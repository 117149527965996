<template>
    <div class="buildingtemplate">
        <myhead :param="param" />
        <section>
            <div class="bannerbuilding">
                <div style="height:280px; "></div>
                <div style="font-size: 44px;font-weight: bold;color: #ffffff;"></div>

            </div>
        </section>
        <section class="body1200" style="margin-top: 90px;">
            <div class="txt18">能源产品应用方案</div>
            <div class="txt60" style="margin-top: 30px">光伏建筑一体化</div>
            <div class="txt18" style="margin-top: 60px;width:860px;">
                光伏建筑一体化是一种将光伏产品集成到建筑上的技术。光伏建筑—体化不同于光伏系统附着在建筑上的形式。光伏建筑一体化可分为两大类：一类是光伏方阵与建筑的结合。另一类是光伏方阵与建筑的集成。如光电瓦屋顶、光电幕墙和光电采光顶等。在这两种方式中，光伏方阵与建筑的结合是一种常用的形式，特别是与建筑屋面的结合。

            </div>
        </section>
        <section class="body1200" style="margin-top: 150px;">
            <div  class="txt42">
                方案特点
            </div>
            <div class="txt18" style="margin-top: 30px;width:860px;">
                将光伏器件与建筑材料集成化。一般的建筑物外围护表面采用涂料、装饰瓷砖或幕墙玻璃，目的是为了保护和装饰建筑物。与建筑相结合的光伏系统，可以作为独立电源或者以并网的方式供电当系统参与并网时，可以不需要蓄电池。但需要与电网的装置，而与并网发电是当今光伏应用的新趋势。
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="b_td b_td1"></div></div>
                <div class="hcell"><div class="b_td b_td2"></div></div>
                <div class="hcell"><div class="b_td b_td3"></div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_tdd">
                    <img :src="b_tddi1">
                    <div class="b_tddh">能够满足建筑美学的要求</div>
                    <div class="b_tddt">光伏建筑一体化适合大多数建筑，如平屋顶、斜屋顶、幕墙、天棚等形式都可以安装。</div>
                </div></div>
                <div class="hcell"><div class="hec_tdd">
                    <img :src="b_tddi2">
                    <div class="b_tddh">能够满足建筑物的采光要求</div>
                    <div class="b_tddt">光伏建筑一体化采用光面超白钢化玻璃制作双面玻璃组件，用来满足建筑物的采光功能。</div>
                </div></div>
                <div class="hcell"><div class="hec_tdd">
                    <img :src="b_tddi3">
                    <div class="b_tddh">能够满足建筑的安全性能要求</div>
                    <div class="b_tddt">把光伏器件用做建材，必须坚固耐用，另外，还要考虑安全性能、外观等因素。</div>
                </div></div>
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_tdd">
                    <img :src="b_tddi4">
                    <div class="b_tddh">经济效益 </div>
                    <div class="b_tddt">采用光伏双玻组件代替，既节约了屋顶材料成本，还能产生一定的经济效益。</div>
                </div></div>
                <div class="hcell"><div class="hec_tdd">
                    <img :src="b_tddi5">
                    <div class="b_tddh">降低墙面及屋顶的温升</div>
                    <div class="b_tddt">光伏阵列吸收太阳能转化为电能，大大降低了室外综合温度，减少了墙体的热。</div>
                </div></div>
                <div class="hcell"><div class="hec_tdd">

                </div></div>
            </div>
        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                方案优势
            </div>
            <div class="txt18" style="width: 860px;margin-top: 60px;">
                建筑与光伏的进一步结合是将光伏器件与建筑材料集成化。一般的建筑物外围护表面采用涂料、装饰瓷砖或幕墙玻璃，目的是为了保护和装饰建筑物。如果用光伏器件代替部分建材，即用光伏组件来做建筑物的屋顶、外墙和窗户，这样既可用做建材也可用以发电，可谓物尽其美。
            </div>
            <div style="margin-top: 56px;">
                <img :src="b_ys">
            </div>
        </section>

        <section class="body1200" style="margin-top: 150px;">
            <div style="font-size: 42px;">
                相关案例
            </div>
            <div class="htable"   style="margin-top: 60px; justify-content: space-between;">
                <div class="hcell"><div class="hec_al hec_al1"><div class="hec_albg1" ><div class="altxt1">
                    <div><img :src="address"></div>
                    <div>四川阿坝1MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al2"><div class="hec_albg2" ><div class="altxt2">
                    <div><img :src="address"></div>
                    <div>四川理塘6MW扶贫光伏项目</div>
                </div> </div></div></div>
                <div class="hcell"><div class="hec_al hec_al3"> <div class="hec_albg3" ><div class="altxt3">
                    <div><img :src="address"></div>
                    <div>重庆巫溪整县扶贫光伏项目</div>
                </div> </div></div></div>
            </div>
        </section>

        <section style="height: 90px;"></section>
    </div>
</template>

<script>
    import myhead  from  '../components/myhead.vue'
    import cpblock_two  from  '../components/cpblock_two.vue'
    import cpblock_one  from  '../components/cpblock_one.vue'
    const param = {
        pageindex: 1,
        rows: 6,
        page: 1,
    }
    export default {
        name: "gflamp" ,
        components: {
            myhead,
            cpblock_two,
            cpblock_one
        },
        data() {
            return {
                param,
                hsccotr: [],
                gprscotr: [],
                g5cotr: [],
                b_tddi1: require('../assets/building_tdi1.png'),
                b_tddi2: require('../assets/building_tdi2.png'),
                b_tddi3: require('../assets/building_tdi3.png'),
                b_tddi4: require('../assets/building_tdi4.png'),
                b_tddi5: require('../assets/building_tdi5.png'),
                b_ys: require('../assets/building_ys.jpg'),
                address: require('../assets/address.png'),
            }
        },
        mounted() {

        },
        methods: {

        },
    }
</script>

<style scoped>
    .buildingtemplate{
        color: #202020;
        text-align: left;

    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }
    .bannerbuilding{
        background-image: url('~assets/building_banner.jpg');
        height: 600px;
    }

    .b_td{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }
    .b_td1{
        background-image: url('~assets/building_td1.jpg');
    }
    .b_td2{
        background-image: url('~assets/building_td2.jpg');
    }
    .b_td3{
        background-image: url('~assets/building_td3.jpg');
    }


    .hec_tdd{
        width: 320px;
        height: 180px;
    }
    .b_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .b_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }


    .hec_al{
        width: 374px;
        height: 270px;
        border-radius: 10px;

    }

    .hec_al1{
        background-image: url('~assets/hec_al1.jpg');
    }
    .hec_al2{
        background-image: url('~assets/hec_al2.jpg');
    }
    .hec_al3{
        background-image: url('~assets/hec_al3.jpg');
    }

    .altxt1{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg1{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt2{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg2{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .altxt3{
        color: #ffffff;
        font-size: 18px;
        text-align: center;
        display: none;
    }
    .hec_albg3{
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items:center;
        display: flex;
    }

    .hec_al1:hover  .hec_albg1 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al1:hover .altxt1{
        display: inline;
    }

    .hec_al2:hover  .hec_albg2 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al2:hover .altxt2{
        display: inline;
    }
    .hec_al3:hover  .hec_albg3 {
        background-color: rgba(255,255,255,0.4)
    }
    .hec_al3:hover .altxt3{
        display: inline;
    }

</style>
